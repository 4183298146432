@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.banner-content {
    font-family: var(--font-primary);

    &::before {
        background-color: var(--background-secondary);
    }
}

.banner-content-subtitle {
    font-family: var(--font-primary);
    font-size: var(--text-xs);

    @include media-breakpoint-up(md) {
        font-size: var(--text-lg);
        line-height: 25px;
    }

    @include media-breakpoint-up(lg) {
        color: var(--text-primary);
        font-size: var(--text-xs);
        line-height: 16px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: var(--text-xs);
        line-height: 24px;
    }

    .alt-colors & {
        color: var(--text-secondary);
    }
}

.banner-content-title {
    color: var(--text-secondary);
    font-family: var(--font-primary);
    font-weight: 700;
    line-height: 32px;
    text-align: left;

    @include media-breakpoint-up(lg) {
        color: var(--text-primary);
        line-height: 40px;
    }

    .alt-colors & {
        color: var(--text-secondary);
    }

    &.desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &.mobile {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.banner-content-description {
    color: var(--text-secondary);
    font-family: var(--font-primary);
    font-size: var(--text-sm);
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 32px;

    @include media-breakpoint-up(lg) {
        color: var(--text-primary);
        font-size: var(--text-base);
        line-height: 24px;
    }

    .alt-colors & {
        color: var(--text-secondary);
    }

    &.desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &.mobile {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.banner-buttons {
    // overriding a:not([href]) rule from node_modules/bootstrap/scss/_reboot.scss
    .btn {
        background-color: var(--background-secondary);
        color: #fff;
        font-family: var(--font-primary);
        font-size: var(--text-sm);
        line-height: 20px;

        /* stylelint-disable-next-line */
        &:hover {
            background-color: var(--background-primary);
        }

        /* stylelint-disable-next-line */
        &:focus {
            color: var(--text-primary);
        }
    }
}
