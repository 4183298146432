@import "../../../../../../../../app_purina_core/cartridge/client/default/scss/experience/components/assets/banner";

#homepage-Purina_AU {
    .banner-content {
        @include media-breakpoint-up(lg) {
            width: 60%;
            max-width: 970px;
        }
    }
}

.banner-buttons {
    .banner-btn {
        border-radius: 99px;
        box-shadow: none;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: rem-calc(1);
        line-height: 16px;
        margin-right: 20px;
        padding: 16px 32px;
        text-decoration: none;
        text-transform: capitalize;
        
        &:hover {
            box-shadow: none;
        }
    }
}
