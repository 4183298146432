@import "../../../../../../../../app_core_page_designer/cartridge/client/default/scss/experience/components/assets/banner";
@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.banner-buttons {
    .c-button--primary {
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        border: 1px solid transparent;
    }
}

.banner-content {
    .text-shadow {
        text-shadow: 1px 3px 5px rgba($color-black, 0.5);
    }
}
