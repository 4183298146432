@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "banner__typography";

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.banner-outer-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
}

.banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @include media-breakpoint-up(md) {
        align-items: center;
        padding-left: 80px;
        position: absolute;
    }

    @include media-breakpoint-up(lg) {
        height: 50%;
        width: 50%;
    }

    &::before {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &.logo-position-top {
        top: 40px;
        align-items: center;
        position: absolute;

        .banner-content-title.desktop {
            padding-top: 50px;
            display: block;

            @include media-breakpoint-down(lg) {
                padding-top: 20px;
                font-size: 32px;
            }
        }

        .banner-content-title.mobile {
            display: none;
        }

        .banner-content-container {
            margin-top: -25px;

            @include media-breakpoint-down(lg) {
                position: relative;
                width: 100%;
                padding: 0 24px;
                margin: -40px auto;
            }
        }

        .banner-logo-top {
            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.banner-content-container {
    margin-top: 24px;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
        /* stylelint-disable-next-line */
        margin-top: 0;
        padding: 0;
    }

    @include media-breakpoint-down(lg) {
        padding: 24px;
        margin-top: 0;
    }
}

.banner-content-title {
    margin-bottom: 16px;
    width: 100%;
}

.banner-content-brand-logo {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
        margin-bottom: 32px;
    }

    &.desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &.mobile {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.banner-content-description {
    margin: 0;
    margin-bottom: 24px;
    width: 100%;

    @include media-breakpoint-up(xl) {
        width: 50%;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
        width: 80%;
    }
}

.banner-buttons {
    display: flex;
    width: 100%;

    .btn {
        border: 0;
        border-radius: 0;
        box-shadow: inset 2px 2px 0 var(--border-secondary), inset -2px -2px 0 var(--border-secondary);
        overflow: hidden;
        // padding: 0 20px;
        padding: 14px 24px;
        text-overflow: ellipsis;
        transition: transform 0.4s;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            padding: 18px 32px;
        }

        /* stylelint-disable-next-line */
        &:hover {
            box-shadow: inset 2px 2px 0 var(--border-primary), inset -2px -2px 0 var(--border-primary);
        }

        /* stylelint-disable-next-line */
        &:hover {
            transform: scale(1.03);
        }

        /* stylelint-disable-next-line */
        &:focus {
            text-decoration: none;
        }

        /* stylelint-disable-next-line */
        &:first-of-type {
            margin-right: 20px;
        }
    }
}

.experience-assets-banner .margins-enabled {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 31px;
    }
}

/* stylelint-disable-next-line */
.experience-layouts-up1ItemCarousel .margins-enabled {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 31px;
    }
}

.logo-display-top {
    @include media-breakpoint-down(sm) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 32px;
        display: flex;
        justify-content: center;
    }
}